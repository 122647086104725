<template>
  <!-- <div id="calemdarEchart" :style="{height:height,width:width}"> -->
  <div ref="calemdarEchart" style="width:100%;height:100%;">
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name:'calendarEchart',
  props: {
    // info: {
    //   type: String,
    //   default: 'chart'
    // },
    echartData:{
      type:Object,
      default:{}
    },
    width: {
      type: String,
      default: '200px'
    },
    height: {
      type: String,
      default: '200px'
    }
  },
  data(){
    return {}
  },
  watch:{
    echartData(n,o){
      this.$nextTick(()=> {
        this.getEchart();
      });
    }
  },
  methods:{
    getEchart(){
        let myChart =  echarts.init(this.$refs.calemdarEchart)
        let option = {
            title: {
                text: '近30日签到用户数趋势',
                left: '3%',
                top:'3%',
                textStyle:{
                  fontSize:'14',
                  fontWeight:'bolder'
                }
            },
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: '4%',
                top:'10%',
                right: '3%',
                bottom: '4%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.echartData.x,
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                }
            },
            series: [
                {   name:'签到用户数',
                    type:'line',
                    itemStyle: {
                      normal: {
                          color: '#409EFF',
                          lineStyle: {
                              color: '#409EFF'
                          }
                      }
                  },
                    stack: '总量',
                    smooth: true,
                    data:this.echartData.y
                }
            ]
        };
        myChart.setOption(option);
    }
  }
}
</script>