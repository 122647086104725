<template>
	<div class="app-container">
		<div style="margin-bottom:10px;">
			<span>签到功能：</span>
			<el-switch
				v-model="IsOpenValue"
				@change="SignConfigIsOpen"
				active-color="#13ce66" >
			</el-switch>
		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="签到统计" name="1"></el-tab-pane>
			<el-tab-pane label="签到设置" name="2"></el-tab-pane>
		</el-tabs>
		<!-- tab1 -->
		<div class="tab-first" v-if="this.activeName=='1'" v-loading='echartLoading'>
			<div class="title">签到概况</div>
			<div class="content">
				<div class="left">
					<calendar-echart :echartData='echartData'></calendar-echart>
				</div>
				<div class="right">
					<div class="item">
						<div style="width: 50%;text-align:center">总签到用户数<div class="text">{{statisticalData.TotalSignMemberCount}} </div>
						</div>
						<div style="width: 50%;text-align:center">总签到次数<div class="text">{{statisticalData.TotalSignCount}} </div>
						</div>
					</div>
					<div class="item">
						<div style="width: 50%;text-align:center">人均签到次数<div class="text">{{statisticalData.AverageCount}} </div>
						</div>
						<div style="width: 50%;text-align:center">订阅签到提醒用户数<div class="text">{{statisticalData.OpenSignWarnCount}} </div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- tab2 -->
		<div class="tab-second" v-else>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" v-loading='loading'>
				<el-form-item label="签到活动名称：">
					<el-input v-model="ruleForm.Title" placeholder="最多输入20个字" maxlength="20" style="width:500px;"></el-input>
				</el-form-item>
				<el-form-item label="日签奖励：" required>
					<span v-if="!ruleForm.IsSendPointDay&&(!ruleForm.IsSendCouponDay)" @click="handleDailyCheckReward(0)" class="basic-text-button">设置</span>
					<div class="daily-check-content">
						<div class="reward-name">
							<div v-if="ruleForm.IsSendPointDay">{{ruleForm.PointNumDay}}个积分</div>
							<div v-if="ruleForm.IsSendCouponDay&&ruleForm.CouponListDayDetail&&ruleForm.CouponListDayDetail.length">
								优惠券名称：{{ruleForm.CouponListDayDetail[0].CouponName}}
								<span>
									<!-- style="color:#F56C6C;" -->
									(赠送数量：{{ruleForm.CouponListDayDetail[0].CouponCount}})
								</span>
							</div>
							<span style="color:#909399;">每日签到成功后可领取该奖励。</span>
						</div>
						<div v-if="ruleForm.IsSendPointDay||ruleForm.IsSendCouponDay">
							<span class="basic-text-button margin-left-style" @click="handleDailyCheckReward(1)">修改奖励</span>
							<span class="error-text-button margin-left-style" @click="handleDeleteDailyCheckReward()">删除奖励</span>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="连签周期："  prop="ContinueSignCycle">
					<el-input style="width:150px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
					 onblur="this.v();if (value==0||value=='-'){value =''}" v-model="ruleForm.ContinueSignCycle"></el-input>
					<span class="margin-left-style">天</span>
					<span style="margin-left:10px;color:#909399;font-size:12px;">若修改周期，该配置将在次日生效</span>
				</el-form-item>
				<el-form-item label="连签奖励：" class="continue-check-content" required>
					<span class="basic-text-button" @click="handleAddContinueReward()">添加奖励</span>
					<span class="remarks">1.周期循环，单个周期内，仅可领取一次连签奖励。若修改周期，将在次日生效</span>
					<span class="remarks">2.若优惠券设置领取次数限制，客户不满足领取条件时将不再发放；若优惠券剩余数量为0，将不再发放。请关注优惠券剩余数量并及时补充</span>
				</el-form-item>
				<el-form-item class="continue-check-content">
					<el-table v-if="ruleForm.ContinueSignSignConfigDetail.length!==0" :data="ruleForm.ContinueSignSignConfigDetail"
					 style="width: 90%;">
						<el-table-column :key="0" label="连签天数">
							<template slot-scope="scope">
								<div>{{scope.row.ContinueSignDay}}天</div>
							</template>
						</el-table-column>
						<el-table-column :key="1" label="奖励">
							<template slot-scope="scope">
								<div v-if="scope.row.IsSendPoint">积分：{{scope.row.PointNum}}</div>
								<div v-if="scope.row.IsSendCoupon&&scope.row.CouponListDetail.length">
									优惠券：{{scope.row.CouponListDetail[0].CouponName}}
									<span v-if="scope.row.CouponListDetail.length&&scope.row.CouponListDetail[0].State&&scope.row.CouponListDetail[0].State!=''"
									 style="color: #F56C6C;margin-left:5px;">
										({{scope.row.CouponListDetail[0].State}})
									</span>
								</div>
								<div v-if="scope.row.IsSendGift&&scope.row.GiftListDetail.length">
									赠品：{{scope.row.GiftListDetail[0].Name}}
									<span v-if="scope.row.GiftListDetail.length&&scope.row.GiftListDetail[0].State&&scope.row.GiftListDetail[0].State!=''"
									 style="color: #F56C6C;margin-left:5px;">
										({{scope.row.GiftListDetail[0].State}})
									</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column :key="2" label="操作">
							<template slot-scope="scope">
								<el-button class="basic-text-button margin-left-style" @click="handleAddContinueReward(scope.row,scope.$index)"
								 type="text" size="small">修改奖励</el-button>
								<el-button style="color:#F56C6C;" @click="handleDeleteContinueReward(scope.$index)" type="text" size="small">删除奖励</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item label="签到规则：">
					<el-input style="width:500px;" v-model="ruleForm.SignRules" type="textarea" :autosize="{ minRows: 4}" maxlength="500"
					 placeholder="请填写签到规则，若未填写，则使用默认规则：
1.每日签到可以获得日签奖励，在单个周期内连续签到可以获得连签奖励，同1个周期内最多可领取1次；
2.每日最多可签到1次，断签则会重新计算连签天数；
3.活动以及奖励详情可联系商家客服了解"></el-input>
				</el-form-item>


			</el-form>
			<div class="footer" v-if="this.activeName=='2'">
				<el-button type="primary" style="width:200px" @click="saveEditForm" v-loading="loading">保存</el-button>
			</div>
		</div>


		<!-- 日签奖励dialog -->
		<el-dialog title="设置日签奖励" :visible.sync="dailySelectDialog" width="50%" class="dialog">
			<div class="content">
				<el-form ref="dailyForm">
					<el-radio @change="handleDailySelect" class="dialog-check-radio" v-model="dailySelect.IsSendPointDay" :label="true">
						送
						<el-input style="width:200px;margin:0 10px;" :disabled="dailySelect.IsSendPointDay==false" v-model="dailySelect.PointNumDay"
						 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
						 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input> 积分
					</el-radio>
					<el-radio @change="handleDailySelect" class="dialog-check-radio" v-model="dailySelect.IsSendPointDay" :label="false">
						<span>送优惠券</span>
						<span v-if="dailySelect.IsSendPointDay==false" class="basic-text-button margin-left-style" @click="handleSelectCouponShow">选择优惠券</span>
					</el-radio>
					<div>
						<el-table v-if="dailySelect.CouponListDayDetail&&dailySelect.CouponListDayDetail.length" :data="dailySelect.CouponListDayDetail">
							<el-table-column :key="4" prop="CouponName" label="优惠券"></el-table-column>
							<el-table-column :key="5" prop="UseTypeValue" label="类型"></el-table-column>
							<el-table-column :key="6" prop="CouponContent" label="优惠内容"></el-table-column>
							<el-table-column :key="7" prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
							<el-table-column :key="8" label="赠送数量/人">
								<template slot-scope="scope">
									<input style="width:100px;height:36px" v-model="scope.row.CouponCount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									 onblur="this.v();if ( value==0||value=='-'){value =''}" />
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template>
									<el-button type="text" style="color:#F56C6C;" @click="handleDeleteDailyCoupon">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-form>

				<!-- <span style="margin-left:20px;" v-if="dailySelect.coupon.name!=''">优惠券名称：{{dailySelect.coupon.name}}</span> -->
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="button" @click="cancelAddCoupon">关闭</el-button>
				<el-button class="button" @click="handleSaveDailySelect" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 连签奖励dialog -->
		<el-dialog title="设置连签奖励" :visible.sync="editContinueDialog" width="50%" class="dialog">
			<div class="content">
				<el-form :model="continueSelect" ref="continueForm" :rules="continueRules">
					<el-form-item label="连签天数：" :inline-message=true prop="ContinueSignDay" :rules="continueRules.ContinueSignDay">
						<el-input style="width:200px;" v-model="continueSelect.ContinueSignDay" prop="continueCheckDays" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0){value =''}"
						 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>
						<span class="margin-left-style">天</span>
					</el-form-item>
					<!-- 送积分 -->
					<el-form-item class="check-item">
						<el-checkbox @change="handleContinuePointCheck" v-model="continueSelect.IsSendPoint">
							<el-form-item prop="PointNum" :inline-message=true :rules="continueSelect.IsSendPoint?continueRules.PointNum:continueRules.nocheck">
								送
								<el-input :disabled="!continueSelect.IsSendPoint" style="margin: 0 5px;width:200px;" v-model="continueSelect.PointNum"
								 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>积分
							</el-form-item>
						</el-checkbox>
					</el-form-item>
					<!-- 选优惠券 -->
					<el-form-item class="check-item">
						<el-checkbox @change="handleContinueCouponCheck" v-model="continueSelect.IsSendCoupon">
							送优惠券
						</el-checkbox>
						<span v-if="continueSelect.IsSendCoupon" style="color:#409EFF;cursor: pointer;margin-left:20px;" @click="handleCoutinueCoupons">选择优惠券</span>
						<!-- <div style="font-size:12px;color:#909399;margin:10px 0 0 25px;">若优惠券设置领取次数限制，客户不满足领取条件时将不再发放；若优惠券剩余数量为0，将不再发放。请关注优惠券剩余数量并及时补充</div> -->
					</el-form-item>

					<!-- 优惠券列表 -->
					<el-form-item class="check-item" v-if="continueSelect.CouponListDetail&&continueSelect.CouponListDetail.length">
						<el-table :data="continueSelect.CouponListDetail" style="width: 90%;margin-left:20px;">
							<!-- <el-table-column :key="9"	  prop="CouponName"	label="优惠券"></el-table-column> -->
							<el-table-column :key="9" prop="CouponName" label="优惠券">
								<template slot-scope="scope">
									<div class="right">
										<div class="name">{{scope.row.CouponName}}</div>
										<span style="color: #E51C23;font-size:12px;">{{scope.row.State}}</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column :key="10" prop="UseTypeValue" label="类型"></el-table-column>
							<el-table-column :key="12" prop="CouponContent" label="优惠内容"></el-table-column>
							<el-table-column :key="11" prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
							<el-table-column :key="13" label="赠送数量/人">
								<template slot-scope="scope">
									<input style="width:100px;height:36px;padding:5px;" v-model="scope.row.CouponCount " onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									 onblur="this.v();if ( value==0||value=='-'){value =''}" />
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template>
									<el-button style="color:#F56C6C;" @click="deleteContinuCoupon" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>

					<!-- 送赠品 -->
					<el-form-item class="check-item" style="margin-bottom:20px">
						<el-checkbox @change="handleContinueProCheck" v-model="continueSelect.IsSendGift">
							送赠品
						</el-checkbox>
						<span v-if="continueSelect.IsSendGift" style="color:#409EFF;cursor: pointer;margin-left:20px;" @click="handleCoutinuePro">选择商品</span>
						<!-- <div style="font-size:12px;color:#909399;margin:10px 0 0 25px;">若优惠券设置领取次数限制，客户不满足领取条件时将不再发放；若优惠券剩余数量为0，将不再发放。请关注优惠券剩余数量并及时补充</div> -->
					</el-form-item>

					<!-- 赠品列表 -->
					<el-form-item class="check-item" v-if="continueSelect.GiftListDetail&&continueSelect.GiftListDetail.length">
						<el-table :data="continueSelect.GiftListDetail" style="width: 90%;margin-left:20px;">
							<el-table-column :key="14" prop="Name" width="370" label="商品">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<img :src='imgUrl+scope.row.ImgUrl' />
										<div class="right">
											<div class="name">
												<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
											</div>
											<span v-if="!scope.row.IsOpen">已下架</span>
											<span v-if="scope.row.Stock<1 ">已售罄</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column :key="15" prop="ProductSpec" label="规格"></el-table-column>
							<el-table-column :key="16" prop="ProductPrice" label="价格"></el-table-column>
							<el-table-column :key="17" prop="Stock" label="库存"></el-table-column>
							<el-table-column :key="18" label="赠送数量" width="120px">
								<template slot-scope="scope">
									<input style="width:100px;height:36px;padding:5px;" v-model="scope.row.ProductCount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									 onblur="this.v();if ( value==0||value=='-'){value =''}" />
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template>
									<el-button style="color:#F56C6C;" @click="deleteContinuPro" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="button" @click="editContinueDialog=false">关闭</el-button>
				<el-button class="button" @click="saveAddContinueReward('continueForm')" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 选择日签优惠券 -->
		<el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="50%" class="dialog data-dialog">
			<select-coupon :couponCheckedId='couponCheckedId' @getSelectCoupon='getSelectCoupon' :visible.sync="selectCouponShow"
			 v-if="selectCouponShow"></select-coupon>
		</el-dialog>
		<!-- :couponCheckedVal='couponCheckedVal' -->

		<!-- 选择连续签优惠券 -->
		<el-dialog title="选择优惠券" :visible.sync="ContinueSelectCoupon" width="50%" class="dialog data-dialog">
			<select-coupon :couponCheckedId='couponCheckedId' @getSelectCoupon='getContinueSelectCoupon' :visible.sync="ContinueSelectCoupon"
			 v-if="ContinueSelectCoupon"></select-coupon>
		</el-dialog>

		<!-- 选择商品-->
		<el-dialog title="选择商品" :visible.sync="selectProShow" width="50%" class="dialog data-dialog">
			<select-produce :proCheckedId='proCheckedId' @getSelection='getSelectList' :visible.sync="selectProShow" v-if="selectProShow"></select-produce>
		</el-dialog>

	</div>
</template>
<script>
	import calendarEchart from "./calendarEchart.vue";
	import selectCoupon from '@/components/SelectCouponDialog'
	import selectProduce from '@/components/SelectSingleProduce'
	import apiList from '@/api/other'
	import config from '@/config/index'
	export default {
		components: {
			calendarEchart,
			selectCoupon,
			selectProduce
		},
		data() {
			var checkContinueSignCycle = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请设置连签周期'))
				} else {
					if (value > 365 || value < 2) {
						this.ruleForm.ContinueSignCycle = ''
						return callback(new Error(`请设置正确的连签天数，2≦天数≦365`))
					} else {
						return callback()
					}
				}
			}
			var checkContinueSignDay = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请设置连签天数'))
				} else {
					let num =Number(value) 
					let maxNum = Number( this.ruleForm.ContinueSignCycle) 
					if (num >maxNum || value < 2) {
						return callback(new Error(`请设置正确的连签天数，2≦天数≦${this.ruleForm.ContinueSignCycle}`))
					} else {
						return callback()
					}
				}
			}
			var checkPointNum = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请设置积分'))
				} else {
					return callback()
				}
			}
			var checkNo = (rule, value, callback) => {
				return callback()
			}
			return {
				IsOpenValue:false,
				currentCycle: 0,
				imgUrl: config.IMG_BASE,
				echartLoading: false,
				loading: false,
				statisticalData: {
					TotalSignMemberCount: 0,
					TotalSignCount: 0,
					AverageCount: 0,
					OpenSignWarnCount: 0,
					SignStatistics: []
				},
				echartData: {
					x: [],
					y: []
				},
				activeName: '1',
				ruleForm: {
					ContinueSignCycle: '',
					PointNumDay: '',
					CouponListDay: [],
					CouponListDayDetail: [],
					ContinueSignSignConfigDetail: [],
					IsSendPointDay: false,
					IsSendCouponDay: false,
					SignRules: '',
				},
				rules: {
					ContinueSignCycle: [{
						trigger: 'blur',
						required:true,
						validator: checkContinueSignCycle
					}]
				},
				dailySelect: {
					IsSendPointDay: true,
					PointNumDay: '',
					CouponListDayDetail: [],
				},
				editRadio: '1',
				dailySelectDialog: false,
				editDialog: false,
				selectCouponShow: false,
				ContinueSelectCoupon: false,
				editContinueDialog: false,
				selectProShow: false,
				continueSelect: {
					ContinueSignDay: '',
					PointNum: '',
					IsSendPoint: false,
					IsSendCoupon: false,
					IsSendGift: false,
					CouponList: [],
					CouponListDetail: [],
					GiftList: [],
					GiftListDetail: []
				},
				continueRules: {
					ContinueSignDay: [{
						trigger: 'blur',
						validator: checkContinueSignDay
					}],
					PointNum: [{
						trigger: 'blur',
						validator: checkPointNum
					}],
					nocheck: [{
						trigger: 'blur',
						validator: checkNo
					}],
				},
				page: {
					total: 0,
					current: 1,
					size: 5,
				},
				couponCheckedId: 0,
				couponCheckedVal: {},
				proCheckedId: 0,
				proCheckedVal: {},
				continueEditIndex: null,
			}
		},
		// beforeMount(){
		//   this.getData()
		// },
		created() {
			this.getData()
			this.getSign()
			this.getFormData()
		},
		methods: {
			async getSign(){
				let result = await apiList.SignConfigIsOpenState({})
				this.IsOpenValue = result.Result.IsOpen
			},
			async SignConfigIsOpen(){
				let result = await apiList.SignConfigIsOpen({
					 IsOpen: this.IsOpenValue
				})
				this.$message({showClose: true,
					type:'success',
					message:result.Message
				})
			},
			handleClick(e) {
				if (e.name == 2) {
					this.getFormData()
				} else {
					this.getData()
				}
			},
			async getData() {
				this.echartLoading = true
				try {
					let result = await apiList.memberSignRecordList({})
					this.statisticalData = result.Result
					this.echartData = {
						x: [],
						y: []
					}
					let echartStatistics = this.statisticalData.SignStatistics
					this.statisticalData.SignStatistics.forEach(t => {
						this.echartData.x.push(t.DateValue)
						this.echartData.y.push(t.SignCount)
					})
					this.echartData.x.reverse()
					this.echartData.y.reverse()
				} catch (e) {
					this.echartLoading = false
				} finally {
					this.echartLoading = false
				}
			},
			//初始化获取
			async getFormData() {
				this.loading = true
				try {
					let result = await apiList.signConfigModel({})
					this.ruleForm = result.Result
					if (this.ruleForm.CouponListDayDetail.length) {
						this.ruleForm.CouponListDayDetail[0].CouponId = this.ruleForm.CouponListDayDetail[0].Id
					}
					this.currentCycle = result.Result.ContinueSignCycle
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			//编辑日常奖励
			handleDailyCheckReward(index) {
				this.$nextTick(() => {
					this.dailySelectDialog = true
				})
				if (index == 0) {
					this.dailySelect = {
						IsSendPointDay: true,
						PointNumDay: '',
						CouponListDayDetail: [],
					}
				} else {
					this.dailySelect = {
						CouponListDayDetail: this.ruleForm.CouponListDayDetail,
						IsSendPointDay: this.ruleForm.IsSendPointDay,
						PointNumDay: this.ruleForm.PointNumDay
					}
				}
				this.$nextTick(() => {
					this.$refs['dailyForm'].clearValidate()
				})
			},
			//删除日常奖励
			handleDeleteDailyCheckReward() {
				this.dailySelect = {
					IsSendPointDay: true,
					PointNumDay: '',
					CouponListDayDetail: [],
				}
				this.ruleForm.PointNumDay = ''
				this.ruleForm.CouponListDayDetail = []
				this.ruleForm.IsSendPointDay = false
				this.ruleForm.IsSendCouponDay = false
			},
			//删除优惠券
			handleDeleteDailyCoupon() {
				this.dailySelect = {
					IsSendPointDay: false,
					PointNumDay: '',
					CouponListDayDetail: []
				}
			},
			// 保存日签奖励选择
			handleSaveDailySelect() {
				if (this.dailySelect.IsSendPointDay) {
					if (this.dailySelect.PointNumDay == '' || this.dailySelect.PointNumDay == 0) {
						this.$message({showClose: true,
							type: 'error',
							message: '请输入积分'
						})
					} else {
						this.ruleForm.PointNumDay = this.dailySelect.PointNumDay
						this.dailySelectDialog = false

						this.ruleForm.IsSendPointDay = this.dailySelect.IsSendPointDay
						this.ruleForm.IsSendCouponDay = !this.dailySelect.IsSendPointDay
					}
				} else {
					if(!this.dailySelect.CouponListDayDetail.length){
						this.$message({showClose: true,
							type: 'error',
							message:'请选择优惠券',
						})
					}else{
						if (!this.dailySelect.CouponListDayDetail[0].CouponCount) {
							this.$message({showClose: true,
								type: 'error',
								message: '请输入赠送数量'
							})
						} else {
							this.ruleForm.CouponListDayDetail = this.dailySelect.CouponListDayDetail
							this.ruleForm.CouponListDayDetail[0].CouponId = this.dailySelect.CouponListDayDetail[0].Id
							this.ruleForm.CouponListDayDetail[0].CouponCount = this.dailySelect.CouponListDayDetail[0].CouponCount
							this.ruleForm.CouponListDayDetail[0].CouponName = this.dailySelect.CouponListDayDetail[0].CouponName
							this.dailySelectDialog = false
							this.ruleForm.IsSendPointDay = this.dailySelect.IsSendPointDay
							this.ruleForm.IsSendCouponDay = !this.dailySelect.IsSendPointDay

						}
					}
				}
			},
			handleDailySelect(e) {
				if (e) {
					this.dailySelect.CouponListDayDetail = []
				} else {
					this.dailySelect.PointNumDay = ''
				}
			},
			//日签奖励，选择优惠券
			handleSelectCouponShow() {
				let id = this.dailySelect.CouponListDayDetail.length == 0 ? 0 : this.dailySelect.CouponListDayDetail[0].Id
				this.couponCheckedId = id
				// this.couponCheckedVal = this.dailySelect.CouponListDayDetail.length==0?{}:this.dailySelect.CouponListDayDetail[0]
				this.selectCouponShow = true
			},
			cancelAddCoupon() {
				this.dailySelectDialog = false
			},
			//获取日签优惠券
			getSelectCoupon(data, val) {
				// 空对象
				data.CouponCount = ''
				if (val) {
					this.dailySelect.CouponListDayDetail = []
					this.dailySelect.CouponListDayDetail.push(data)
				}
				this.selectCouponShow = false
			},

			//连签奖励，选择优惠券
			handleCoutinueCoupons() {
				let id = this.continueSelect.CouponListDetail.length ? this.continueSelect.CouponListDetail[0].Id : 0
				this.couponCheckedId = id
				// this.couponCheckedVal = this. continueSelect.CouponListDetail[0]
				this.ContinueSelectCoupon = true
			},
			//获取连续签到优惠券
			getContinueSelectCoupon(data, val) {
				data.CouponCount = ''
				if (val) {
					this.continueSelect.CouponListDetail = []
					this.continueSelect.CouponListDetail.push(data)
				}
				this.ContinueSelectCoupon = false
			},
			//连签奖励，选择商品
			handleCoutinuePro() {
				let id = this.continueSelect.GiftListDetail.length ? this.continueSelect.GiftListDetail[0].ProductSpecId : 0
				this.proCheckedId = id
				this.selectProShow = true
			},
			//获取选择的商品列表
			getSelectList(data, val) {
				data.ProductCount = ''
				if (val) {
					this.continueSelect.GiftListDetail = []
					this.continueSelect.GiftListDetail.push(data)
					this.continueSelect.GiftListDetail[0].ProductSpec = data.SpecValue
				}
				this.selectProShow = false
			},
			handleContinuePointCheck(e) {
				if (!e) {
					this.continueSelect.PointNum = ''
				}
			},
			handleContinueCouponCheck(e) {
				if (!e) {
					this.continueSelect.CouponListDetail = []
				}
			},
			handleContinueProCheck(e) {
				if (!e) {
					this.continueSelect.GiftListDetail = []
				}
			},
			//删除连签奖励，优惠券
			deleteContinuCoupon() {
				this.continueSelect.CouponListDetail = []
			},
			//删除连签奖励，赠品
			deleteContinuPro() {
				this.continueSelect.GiftListDetail = []
			},
			//添加连续奖励
			handleAddContinueReward(val, index) {
				if (this.ruleForm.ContinueSignCycle != '' && this.ruleForm.ContinueSignCycle != 0) {
					if (index == undefined) {
						this.continueEditIndex = null
					} else {
						this.continueEditIndex = index
					}
					//先清空
					this.continueSelect = {
						ContinueSignDay: '',
						CouponList: [],
						CouponListDetail: [],
						GiftList: [],
						GiftListDetail: [],
						IsSendCoupon: false,
						IsSendGift: false,
						IsSendPoint: false,
						PointNum: ''
					}
					if (val) {
						this.continueSelect = Object.assign({}, val)
					}
					this.editContinueDialog = true
					this.$nextTick(() => {
						this.$refs['continueForm'].clearValidate()
					})
				} else {
					this.$message({showClose: true,
						type: 'info',
						message: '请设置连签周期'
					})
				}
			},
			//删除连续奖励
			handleDeleteContinueReward(val) {
				this.ruleForm.ContinueSignSignConfigDetail.splice(val, 1)
			},
			//保存连续签到奖励
			saveAddContinueReward(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if ((!this.continueSelect.IsSendPoint) && (!this.continueSelect.IsSendCoupon) && (!this.continueSelect.IsSendGift)) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择奖励'
							})
						} else if (this.continueSelect.IsSendPoint && this.continueSelect.PointNum == '') {
							this.$message({showClose: true,
								type: 'error',
								message: '请设置积分'
							})
						} else if (this.continueSelect.IsSendCoupon && this.continueSelect.CouponListDetail.length == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择优惠券'
							})
						} else if (this.continueSelect.IsSendCoupon && this.continueSelect.CouponListDetail[0].CouponCount == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请填写赠送数量'
							})
						} else if (this.continueSelect.IsSendGift && this.continueSelect.GiftListDetail.length == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择商品'
							})
						} else if (this.continueSelect.IsSendGift && this.continueSelect.GiftListDetail[0].ProductCount == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请填写赠送数量'
							})
						} else {
							let value = this.continueSelect
							if (this.continueEditIndex == null) {
								this.ruleForm.ContinueSignSignConfigDetail.push(value)
							} else {
								this.$set(this.ruleForm.ContinueSignSignConfigDetail, this.continueEditIndex, value)
							}
							this.$forceUpdate()
							this.editContinueDialog = false
						}
					} else {
						console.log('error submit')
					}
				})
			},
			async saveEditData() {
				this.loading = true
				try {
					let data = {
						ContinueSignCycle: this.ruleForm.ContinueSignCycle,
						ContinueSignSignConfig: this.ruleForm.ContinueSignSignConfigDetail,
						CouponListDay: this.ruleForm.CouponListDay,
						IsSendCouponDay: this.ruleForm.IsSendCouponDay,
						IsSendPointDay: this.ruleForm.IsSendPointDay,
						PointNumDay: this.ruleForm.PointNumDay,
						SignRules: this.ruleForm.SignRules,
						Title: this.ruleForm.Title,
					}
					let result = await apiList.signConfigEdit(data)
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '保存成功'
						})
						this.getFormData()
					}
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			//保存
			saveEditForm() {
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {

						if (this.ruleForm.CouponListDay && this.ruleForm.IsSendCouponDay) {
							this.ruleForm.CouponListDay[0] = {
								CouponId: this.ruleForm.CouponListDayDetail[0].Id,
								CouponCount: this.ruleForm.CouponListDayDetail[0].CouponCount,
								CouponName: this.ruleForm.CouponListDayDetail[0].CouponName,
							}
						} else {
							this.ruleForm.CouponListDay = []
						}

						this.ruleForm.ContinueSignSignConfigDetail.forEach(t => {
							if (t.CouponListDetail && t.CouponListDetail.length) {
								t.CouponList[0] = {
									CouponId: t.CouponListDetail[0].Id,
									CouponCount: t.CouponListDetail[0].CouponCount,
									CouponName: t.CouponListDetail[0].CouponName
								}
							} else {
								t.CouponList = []
							}
							if (t.GiftListDetail && t.GiftListDetail.length) {
								t.GiftList[0] = {
									ProductId: t.GiftListDetail[0].Id,
									ProductSpecId: t.GiftListDetail[0].ProductSpecId,
									ProductCount: t.GiftListDetail[0].ProductCount,
									ProductName: t.GiftListDetail[0].Name
								}
							} else {
								t.GiftList = []
							}
						})
						if (this.currentCycle != this.ruleForm.ContinueSignCycle) {
							this.$confirm('您正在修改周期天数，修改成功后的周期次日生效，现周期今日结束。请确认是否继续修改？', '提示', {
								confirmButtonText: '继续修改',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(async () => {
								this.loading = true
								try {
									let data = {
										ContinueSignCycle: this.ruleForm.ContinueSignCycle,
										ContinueSignSignConfig: this.ruleForm.ContinueSignSignConfigDetail,
										CouponListDay: this.ruleForm.CouponListDay,
										IsSendCouponDay: this.ruleForm.IsSendCouponDay,
										IsSendPointDay: this.ruleForm.IsSendPointDay,
										PointNumDay: this.ruleForm.PointNumDay,
										SignRules: this.ruleForm.SignRules,
										Title: this.ruleForm.Title,
									}
									let result = await apiList.signConfigEdit(data)
									if (result.IsSuccess) {
										this.$message({showClose: true,
											type: 'success',
											message: '保存成功'
										})
										this.getFormData()
									}
								} catch (e) {
									this.loading = false
								} finally {
									this.loading = false
								}
							}).catch(() => {
								this.$message({showClose: true,
									type: 'info',
									message: '已取消修改'
								});
							})
						} else {
							try {
								this.loading = true
								let data = {
									ContinueSignCycle: this.ruleForm.ContinueSignCycle,
									ContinueSignSignConfig: this.ruleForm.ContinueSignSignConfigDetail,
									CouponListDay: this.ruleForm.CouponListDay,
									IsSendCouponDay: this.ruleForm.IsSendCouponDay,
									IsSendPointDay: this.ruleForm.IsSendPointDay,
									PointNumDay: this.ruleForm.PointNumDay,
									SignRules: this.ruleForm.SignRules,
									Title: this.ruleForm.Title,
								}
								let result = await apiList.signConfigEdit(data)
								if (result.IsSuccess) {
									this.$message({showClose: true,
										type: 'success',
										message: '保存成功'
									})
									this.getFormData()
								}
							} catch (e) {
								this.loading = false
							} finally {
								this.loading = false
							}
						}
					}
				})
			}
		}
	}
</script>
<style lang='less' scoped>
	.app-container {
		overflow: hidden;
		background-color: #fff;

		margin-bottom: 90px;
	}

	.basic-text-button {
		color: #409EFF;
		margin-left: 10px;
		cursor: pointer;
	}

	.error-text-button {
		color: #F56C6C;
		margin-left: 10px;
		cursor: pointer;
	}

	.margin-left-style {
		margin-left: 10px;
	}

	.tab-first {
		width: 100%;
		border: 1px solid #ddd;
		padding: 10px;

		.content {
			width: 100%;
			margin-top: 30px;
			overflow: hidden;

			.left {
				width: 800px;
				overflow: hidden;
				float: left;
				height: 500px;
				background-color: #F5F5F5;
			}

			.right {
				width: calc(100% - 820px);
				float: right;
				background-color: #F5F5F5;
				height: 500px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				.item {
					display: flex;
					flex-direction: row;
					// justify-content: space-around;
					align-items: center;
				}

				.text {
					line-height: 50px;
					font-size: 24px;
				}
			}
		}
	}

	.tab-second {
		.daily-check-content {
			display: flex;
			flex-direction: row;

		}

		.continue-check-content {
			.remarks {
				color: #909399;
				font-size: 12px;
				display: block;
				line-height: 20px !important;
			}
		}
	}

	.dialog-check-radio {
		display: block;
		margin-bottom: 10px;

	}

	.dialog-footer {
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.check-item {
		// margin-bottom: 20px;
		margin-left: 43px;
	}

	.footer {
		position: fixed;
		background-color: #fff;
		bottom: 0;
		width: 100%;
		line-height: 80px;
		text-align: center;
		left: 0;
		box-shadow: 13px 1px 6px #999;
		z-index: 999;
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 40px;
			height: 40px;
			object-fit: contain;
			margin-right: 10px;
			border: 1px solid #ddd;
		}

		.name {
			width: 304px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}
</style>
